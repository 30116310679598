
import { GetSchool as schoolList } from "@/Api/Basics";
import Storage from "@/util/Storage";
import {filterList} from "@/views/PlatformAdmin/SchoolAdmin/Util";
import {deleteSchoolAdmin} from "@/Api/Platform/bascs";
import {Toast} from "vant";

export function getSchoolList(body:any = {}) {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");

    return Promise.resolve(schoolList({ userId,token },body).then(res=>{
		// console.log("+++++")
		// console.log("----",res.data)
        return filterList(res.data)
    }))
}


// 删除学校
export function delSchool(id:string|number = "") {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    Toast.loading({ duration:0,message:"删除中~~" })
    return Promise.resolve(deleteSchoolAdmin({ userId,token },{ id }).then(res=>{
        Toast.clear()
        return res.data
    }))
}
