
// 过滤时间
export function filterList( list:any[] = [] ){
    let newData:any = {}
    if ( Array.isArray(list) ) {
        list.forEach( (item,index)=>{
			console.log(item)
			// console.log(index)
            let FirstCode = ""
            if(item.code!=null && item.code!=undefined){
                  FirstCode = item.code.slice(0,1)
            }
            console.log(FirstCode)
            let Str = FirstCode.toUpperCase()
            let keyJson = newData[Str]
			// console.log(keyJson)
            if ( Boolean(keyJson) ){
                newData[Str].push(item)
            }else{
                newData[Str] = [ item ]
            }
        } )
    }
    let KeyArr = Object.keys(newData)
    let leng=KeyArr.length
    KeyArr = KeyArr.sort().slice(2,leng)
    console.log(KeyArr)
    return {
        list:KeyArr,
        data:newData
    }
}
